import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  type NormalizedCacheObject,
  type QueryOptions,
  from,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
// import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';

import { createClient } from 'graphql-ws';
import { type ReactNode, createContext, useContext, useMemo } from 'react';
import { getCurrentUser } from './AuthContext';

import { companyPartsCacheKeyArgs, partsCacheKeyArgs } from '@/modules/parts/consts';
import type { WorkOrdersWithPaginationQueryVariables } from '@/modules/workOrders/graphql/workOrders.generated';
import type { CanReadFunction } from '@apollo/client/cache/core/types/common';
import { relayStylePagination } from '@apollo/client/utilities';
import { getMainDefinition, offsetLimitPagination } from '@apollo/client/utilities';

type CacheItem = {
  id: number;
  status: string;
  __ref?: string;
};

type UpdatePaginationQueryParams = {
  query: QueryOptions;
  queryName: string;
  itemsField: string;
  targetItem: CacheItem;
};

export type GraphqlContextProps = {
  client?: ApolloClient<NormalizedCacheObject>;
  evictObjectFromCache: (id: string) => void;
  updatePaginationQueryCache: (params: UpdatePaginationQueryParams) => void;
};

export type GraphqlProps = {
  children: ReactNode;
};

type CacheDataWithTotalCount = Record<string, CacheItem[]> & {
  totalCount: number;
};

const GraphqlContext = createContext<GraphqlContextProps>({
  client: undefined,
  evictObjectFromCache: () => undefined,
  updatePaginationQueryCache: () => undefined,
});

export const useGraphqlContext = () => useContext(GraphqlContext);

export const GraphqlProvider = ({ children }: GraphqlProps) => {
  const { client, evictObjectFromCache, updatePaginationQueryCache } = useMemo(() => {
    const httpLink = new HttpLink({
      uri: `${import.meta.env.VITE_BACKEND_URL}/graphql`,
    });

    const authLink = setContext(async (_, { headers }) => {
      const token = await (await getCurrentUser()).getIdToken();
      if (!token) throw Error('トークンが存在しません。');
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token}`,
        },
      };
    });

    const wsLink = new GraphQLWsLink(
      createClient({
        url: `${import.meta.env.VITE_WS_URL}/graphql`,
        lazy: true,
        shouldRetry: () => true,
        connectionParams: async () => {
          try {
            const token = await (await getCurrentUser()).getIdToken();
            if (!token) throw Error('トークンが存在しません。(WS)');

            return {
              authToken: token,
            };
          } catch (error) {
            console.error(error);
            return {};
          }
        },
      })
    );

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      from([authLink, httpLink])
    );

    const evictObjectFromCache = (id = '') => {
      if (client && id) {
        const { cache } = client;
        cache.evict({ id });
        cache.gc();
      }
    };

    const updatePaginationQueryCache = (params: UpdatePaginationQueryParams) => {
      const { cache } = client;
      if (!cache) return;

      const { query, queryName, itemsField, targetItem } = params;

      cache.updateQuery(query, (dataInCache) => {
        if (!dataInCache?.[queryName]) return;

        const statuses: string[] = query.variables?.statuses || [];

        const {
          [itemsField]: itemsInCache = [],
          totalCount: totalCountInCache = 0,
        }: CacheDataWithTotalCount = dataInCache[queryName];

        const isItemExistInCache = itemsInCache.find((item) => item.id === targetItem.id);
        const shouldAddItemToCache = statuses.includes(targetItem.status) && !isItemExistInCache;
        const shouldRemoveItemFromCache =
          !statuses.includes(targetItem.status) && !!isItemExistInCache;

        if (shouldAddItemToCache || shouldRemoveItemFromCache) {
          const updatedItems = shouldAddItemToCache
            ? [targetItem, ...itemsInCache]
            : itemsInCache.filter((item) => item.id !== targetItem.id);

          const totalCount = shouldAddItemToCache ? totalCountInCache + 1 : totalCountInCache - 1;

          return {
            [queryName]: {
              [itemsField]: updatedItems,
              totalCount,
            },
          };
        }

        return dataInCache;
      });
    };

    const filterAndAdjustPagination = (
      dataInCache: CacheDataWithTotalCount,
      itemsFieldKey: string,
      canRead: CanReadFunction
    ) => {
      if (dataInCache?.[itemsFieldKey]) {
        const itemsInCache = dataInCache[itemsFieldKey];
        if (Array.isArray(itemsInCache)) {
          const filteredItems = itemsInCache?.filter(canRead);
          const totalCountDiff = itemsInCache.length - filteredItems.length;

          return {
            ...dataInCache,
            [itemsFieldKey]: filteredItems,
            totalCount: Number(dataInCache.totalCount - totalCountDiff),
          };
        }
      }
    };

    const workOrderPaginationQueryKeyArgs: (keyof WorkOrdersWithPaginationQueryVariables)[] = [
      'statuses',
      'from',
      'to',
      'assetIds',
      'productIds',
      'searchField',
      'createdByIds',
      'assigneeIds',
      'groupIds',
      'otherFilters',
      'priorities',
      'sortBy',
      'ordering',
      'customFieldOptionIds',
      'hasCheckList',
      'hasRequest',
      'dueDateFrom',
      'dueDateTo',
      'stoppageStartAtFrom',
      'stoppageStartAtTo',
      'isScheduled',
      'stoppageReasonIds',
    ];

    const errorLink = onError(({ graphQLErrors }) => {
      if (graphQLErrors) {
        const isInvalidLoginProviderError = graphQLErrors.some((error) => {
          return (
            error.extensions?.code === 'UNAUTHENTICATED' &&
            error.message.includes('invalid login provider')
          );
        });

        if (isInvalidLoginProviderError) {
          console.warn('invalid login provider');
          document.location.href = '/logout';
        }
      }
    });

    const client = new ApolloClient({
      link: from([errorLink, splitLink]),
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              parts: offsetLimitPagination(partsCacheKeyArgs),
              companyParts: offsetLimitPagination(companyPartsCacheKeyArgs),
              reports: offsetLimitPagination(['filters']),
              workflowExecutions: offsetLimitPagination(['sortBy']),
              workOrdersWithPagination: {
                keyArgs: workOrderPaginationQueryKeyArgs,
                merge(existing = { workOrders: [] }, incoming, { args }) {
                  if (!incoming) return existing;

                  const mergedWorkOrders: CacheItem[] = existing.workOrders.slice(0);
                  const incomingWorkOrders: CacheItem[] = incoming.workOrders;

                  if (args) {
                    const { page, limit } = args;
                    // Note: If page and limit are absent, it indicates that the incoming data is the result after the
                    // updatePaginationQueryCache function. Thus, we can override the cache with this incoming data.
                    // Otherwise, we merge the incoming data with the existing data to prevent cache override
                    // See doc: https://www.apollographql.com/docs/react/pagination/core-api#merging-paginated-results
                    if (!page && !limit) return incoming;

                    mergedWorkOrders.push(...incomingWorkOrders);

                    // Note: We need to remove duplicates from the mergedWorkOrders array
                    const workOrders = mergedWorkOrders.filter(
                      (workOrder, index, self) =>
                        index === self.findIndex((wo) => wo.__ref === workOrder.__ref)
                    );

                    return {
                      ...incoming,
                      workOrders,
                    };
                  }
                  throw new Error('args not found');
                },
                read(dataInCache, { canRead }) {
                  return filterAndAdjustPagination(dataInCache, 'workOrders', canRead);
                },
              },
              requests(dataInCache, { canRead }) {
                return filterAndAdjustPagination(dataInCache, 'requests', canRead);
              },
              checkListTemplates(dataInCache, { canRead }) {
                if (dataInCache && Array.isArray(dataInCache)) {
                  return dataInCache?.filter(canRead);
                }
                return dataInCache;
              },
            },
          },
          Chat: {
            fields: {
              paginatedChatMessages: relayStylePagination(),
            },
          },
          ReportTemplate: {
            fields: {
              reports: offsetLimitPagination(),
            },
          },
          WorkflowState: {
            // state.idが同じ(Approval:1, Created等)が同じでも中身が違うことがあるのでnormalizeして保存しない
            keyFields: false,
          },
        },
        possibleTypes: {
          WorkflowExecutionEvent: [
            'WorkflowSubmittedEvent',
            'WorkflowCancelledEvent',
            'WorkflowRejectedEvent',
            'WorkflowApprovedEvent',
            'WorkflowResubmittedEvent',
          ],
        },
      }),
      connectToDevTools: import.meta.env.DEV,
    });

    return { client, evictObjectFromCache, updatePaginationQueryCache };
  }, []);

  return (
    <GraphqlContext.Provider value={{ client, evictObjectFromCache, updatePaginationQueryCache }}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </GraphqlContext.Provider>
  );
};
